import * as React from "react";

interface IGoogleIconProps {
  white?: boolean;
}

function GoogleIcon({ white = true, ...props }: IGoogleIconProps & React.SVGProps<any>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
      <g fill="none">
        <path
          fill={white ? "#ffffff" : "#fbbb00"}
          d="M3.99 10.878l-.627 2.339-2.29.048A8.96 8.96 0 010 9c0-1.492.363-2.9 1.006-4.139l2.04.374.893 2.026A5.35 5.35 0 003.649 9c.001.66.12 1.294.34 1.878z"
        />
        <path
          fill={white ? "#ffffff" : "#518ef8"}
          d="M17.843 7.319a9.01 9.01 0 01-.04 3.56 8.998 8.998 0 01-3.169 5.14v-.001l-2.568-.131-.363-2.269a5.364 5.364 0 002.307-2.739H9.198V7.32h8.645z"
        />
        <path
          fill={white ? "#ffffff" : "#28b446"}
          d="M14.634 16.018A8.962 8.962 0 019 18a8.999 8.999 0 01-7.927-4.735l2.916-2.387a5.351 5.351 0 007.713 2.74l2.932 2.4z"
        />
        <path
          fill={white ? "#ffffff" : "#f14336"}
          d="M14.745 2.072l-2.916 2.387A5.353 5.353 0 003.94 7.26l-2.932-2.4A8.998 8.998 0 019 0c2.184 0 4.186.778 5.745 2.072z"
        />
      </g>
    </svg>
  );
}

export default GoogleIcon;
