import gql from "graphql-tag";

export default gql`
  mutation SignUpFromGoogleOAuthAccessToken($accessToken: String!, $utms: UTMParametersInput) {
    signUpFromGoogleOAuthAccessToken(accessToken: $accessToken, utms: $utms) {
      email
      isRecentlyCreatedUser
      accessToken
    }
  }
`;
