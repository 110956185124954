import classNames from "components/ui/classNames";

interface IFooterProps {
  className?: string;
}
export default function Footer({ className }: IFooterProps) {
  return (
    <footer className={classNames("text-center text-sm font-normal text-gray-500", className)}>
      <p>© {new Date().getFullYear()} Fundamentei</p>
    </footer>
  );
}
