import type { ReactElement } from "react";

interface IDividerProps {
  children: any;
}

export default function Divider({ children }: IDividerProps): ReactElement {
  return (
    <div className="grid select-none grid-cols-[1fr_max-content_1fr] items-center gap-x-3">
      <div className="h-px w-full border-b border-neutral-200" />

      <div>{children}</div>

      <div className="h-px w-full border-b border-neutral-200" />
    </div>
  );
}
