import type { IntlShape } from "react-intl";
import { z } from "zod";

export function changePasswordFormDataShape(intl: IntlShape) {
  return z.object({
    currentPassword: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          description: "Formulário de mudança de senha",
          defaultMessage: "A senha antiga é obrigatória",
          id: "KdtxBS",
        }),
      )
      .min(
        8,
        intl.formatMessage({
          description: "Formulário de mudança de senha",
          defaultMessage: "A senha antiga deve ter no mínimo 8 dígitos",
          id: "reFSKr",
        }),
      ),
    newPassword: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "A nova senha é obrigatória",
          id: "94KBXG",
        }),
      )
      .min(
        8,
        intl.formatMessage({
          description: "Formulário de mudança de senha",
          defaultMessage: "A nova senha deve ter no mínimo 8 dígitos",
          id: "INpVbN",
        }),
      ),
  });
}
export type ChangePasswordFormData = z.infer<ReturnType<typeof changePasswordFormDataShape>>;

export function forgotPasswordDataShape(intl: IntlShape) {
  return z.object({
    email: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Seu email é obrigatório",
          id: "wRklmD",
        }),
      )
      .email(
        intl.formatMessage({
          defaultMessage: "O email fornecido é inválido",
          id: "D3xyXY",
        }),
      ),
  });
}
export type ForgorPasswordFormData = z.infer<ReturnType<typeof forgotPasswordDataShape>>;

export function loginCredentialsDataShape(intl: IntlShape) {
  return z.object({
    email: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Seu email é obrigatório",
          id: "wRklmD",
        }),
      )
      .email(
        intl.formatMessage({
          defaultMessage: "O email fornecido é inválido",
          id: "D3xyXY",
        }),
      ),
    password: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Sua senha é obrigatória",
          id: "ljxTQR",
        }),
      )
      .min(
        8,
        intl.formatMessage({
          defaultMessage: "A senha deve ter no mínimo 8 dígitos",
          id: "riNPes",
        }),
      ),
  });
}
export type LoginCredentialsDataShape = z.infer<ReturnType<typeof loginCredentialsDataShape>>;

export function resetPasswordFormDataShape(intl: IntlShape) {
  return z
    .object({
      newPassword: z
        .string()
        .trim()
        .nonempty(
          intl.formatMessage({
            defaultMessage: "A nova senha é obrigatória",
            id: "94KBXG",
          }),
        )
        .min(
          8,
          intl.formatMessage({
            defaultMessage: "A senha deve ter no mínimo 8 dígitos",
            id: "riNPes",
          }),
        ),
      passwordConfirmation: z.string().nonempty(
        intl.formatMessage({
          description: "Formulário de redefinição de senha",
          defaultMessage: "A confirmação de senha é obrigatória",
          id: "BZpJ7Q",
        }),
      ),
    })
    .refine((data) => data.newPassword === data.passwordConfirmation, {
      message: intl.formatMessage({
        description: "Formulário de redefinição de senha",
        defaultMessage: "As senhas digitadas não conferem",
        id: "aj035l",
      }),
      path: ["passwordConfirmation"],
    });
}
export type ResetPasswordFormDataShape = z.infer<ReturnType<typeof resetPasswordFormDataShape>>;

export function setPasswordFormDataShape(intl: IntlShape) {
  return z.object({
    newPassword: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "A nova senha é obrigatória",
          id: "94KBXG",
        }),
      )
      .min(
        8,
        intl.formatMessage({
          defaultMessage: "A nova senha deve ter no mínimo 8 dígitos",
          id: "DlrwKU",
        }),
      ),
  });
}
export type SetPasswordFormData = z.infer<ReturnType<typeof setPasswordFormDataShape>>;

export function getSignUpFormDataShape(intl: IntlShape) {
  return z.object({
    firstName: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Seu nome é obrigatório",
          description: "Sign up form",
          id: "QSgzF9",
        }),
      )
      .min(
        2,
        intl.formatMessage({
          defaultMessage: "O nome é muito curto",
          description: "Sign up form",
          id: "mTVJrP",
        }),
      )
      .max(
        36,
        intl.formatMessage({
          defaultMessage: "Seu sobrenome deve ter no máximo 36 caracteres",
          description: "Sign up form",
          id: "ZFIwSG",
        }),
      ),
    lastName: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Seu sobrenome é obrigatório",
          description: "Sign up form",
          id: "Qpbdzf",
        }),
      )
      .min(
        2,
        intl.formatMessage({
          defaultMessage: "Seu sobrenome é muito curto",
          description: "Sign up form",
          id: "2R0PPA",
        }),
      )
      .max(
        36,
        intl.formatMessage({
          defaultMessage: "Seu sobrenome deve ter no máximo 36 caracteres",
          description: "Sign up form",
          id: "ZFIwSG",
        }),
      ),
    email: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Seu email é obrigatório",
          id: "wRklmD",
        }),
      )
      .email(
        intl.formatMessage({
          defaultMessage: "O email fornecido é inválido",
          id: "D3xyXY",
        }),
      ),
    password: z
      .string()
      .trim()
      .nonempty(
        intl.formatMessage({
          defaultMessage: "Você deve definir uma senha",
          description: "Sign up form",
          id: "8umz1A",
        }),
      )
      .min(
        8,
        intl.formatMessage({
          defaultMessage: "A senha deve ter no mínimo 8 dígitos",
          id: "riNPes",
        }),
      ),
  });
}
export type SignUpFormData = z.infer<ReturnType<typeof getSignUpFormDataShape>>;
