import { useEffect, useState } from "react";

type TUseFlashMessageTuple = [string, (message: string) => void];

export default function useFlashMessage(clearAfterMs = 5000): TUseFlashMessageTuple {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    let lastScheduledTimeout: NodeJS.Timeout;

    if (setMessage) {
      lastScheduledTimeout = setTimeout(() => {
        setMessage("");
      }, clearAfterMs);
    }

    return () => clearTimeout(lastScheduledTimeout);
  }, [message]);

  return [message, setMessage];
}
