import GoogleIcon from "components/icons/GoogleIcon";
import { Button } from "components/ui/Button";
import useOAuth from "lib/hooks/useOAuth";
import getBaseUrl from "lib/utils/getBaseUrl";
import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface IGoogleOAuthButtonProps {
  children: ReactNode;
  onGetAccessToken: (accessToken: string) => void;
}

export default function GoogleOAuthButton({ children, onGetAccessToken }: IGoogleOAuthButtonProps) {
  const oauthCallbackHref = encodeURIComponent(`${getBaseUrl()}/oauth-callback`);
  const connectWithGoogleHref = `https://oauth.fundamentei.io/connect/google?redirect=${oauthCallbackHref}`;

  const { loading, start, cancel } = useOAuth(connectWithGoogleHref, ({ accessToken }) => {
    onGetAccessToken(accessToken);
  });

  return (
    <Button
      type="button"
      variant="outline"
      className="w-full space-x-2 text-center text-slate-600 hover:text-brand"
      onClick={() => {
        if (loading) {
          cancel();
        } else {
          start();
        }
      }}
    >
      {loading ? (
        <FormattedMessage defaultMessage="Cancelar" id="nZLeaQ" />
      ) : (
        <>
          <GoogleIcon white={false} width={18} height={18} />
          <span>{children}</span>
        </>
      )}
    </Button>
  );
}
