import classNames from "components/ui/classNames";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={classNames(
        "flex h-10 w-full rounded-md border border-slate-300 px-3 py-2 text-sm",
        "file:border-0 file:bg-transparent file:text-sm file:font-medium",
        "placeholder:text-slate-400",
        "disabled:cursor-not-allowed disabled:opacity-50",
        "dark:border-slate-800 dark:bg-slate-950 dark:text-slate-300 dark:focus-visible:outline-gray-600",
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

const TextArea = React.forwardRef<HTMLTextAreaElement, React.TextareaHTMLAttributes<HTMLTextAreaElement>>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        {...props}
        ref={ref}
        className={classNames(
          "flex w-full rounded-md border border-slate-300 px-3 py-2 text-sm",
          "file:border-0 file:bg-transparent file:text-sm file:font-medium",
          "placeholder:text-slate-400",
          "disabled:cursor-not-allowed disabled:opacity-50",
          "dark:border-slate-800 dark:bg-slate-950 dark:text-slate-300",
          "dark:text-white dark:focus-visible:outline-none dark:focus-visible:ring-2 dark:focus-visible:ring-inset dark:focus-visible:ring-gray-600",
          "resize-none",
          className,
        )}
      />
    );
  },
);
TextArea.displayName = "TextArea";

export { Input, TextArea };
